@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

.control .icon {
  pointer-events: all;
}



$family-sans-serif: "Roboto", sans-serif;
$info: #b9e1dc;
$link: #7080da;
// $success: #49d67f;
$danger: #d45f46;

$orange: #ff8606;
$orange-invert: findColorInvert($orange);

$footer-padding: 1.5rem 1.5rem 1.5rem;

$main-1: #35bbbb;
$main-2: #1887da;
$main-3: #0249bb;
$main-4: #2823b2;
$main-5: #0e1266;


$main-1-invert: findColorInvert(main-1);
$main-2-invert: findColorInvert(main-2);
$main-3-invert: findColorInvert(main-3);
$main-4-invert: findColorInvert(main-4);
$main-5-invert: findColorInvert(main-5);

$accent-1: #9931e1;
$accent-2: #3fccd5;

$accent-1-invert: findColorInvert(accent-1);
$accent-2-invert: findColorInvert(accent-2);


$custom-colors: (
  "main-1": ($main-1),
  "main-2": ($main-2),
  "main-3": ($main-3),
  "main-4": ($main-4),
  "main-5": ($main-5),
  "accent-1": ($accent-1),
  "accent-2": ($accent-2)
);

$body-background-color: #fbfbfb;
$footer-padding: 1.5rem 1.5rem 1.5rem;

// $menu-item-active-background-color: $main-3;

@import "../node_modules/bulma/bulma.sass";

html,
body {
  background-color: white;
  min-height: 100vh;
}

.pointer {
  cursor: pointer;
}

// .react-datepicker-wrapper{
//   justify-content: center

// }
.react-datepicker__input-container {
  text-align: center;
}

.fa-small {
  font-size: 6px;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#wrapper {
  flex: 1;
}


.is-down {
  margin-top: auto;
}